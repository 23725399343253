import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { ISliderVanillaProps } from './types';
import { CarouselSlideLeft, CarouselSlideRight, CarouselWrapper } from './styles';
import { Swiper as SwiperType } from 'swiper/types';

export const Slider = ({
  slidesPerView = 1,
  isFullWidth = false,
  isStaticArrows = false,
  enableDots = true,
  className = '',
  isOverflowHidden = true,
  children,
}: ISliderVanillaProps) => {
  const swiperRef = useRef<SwiperType | null>(null);
  const [swiperState, setSwiperState] = useState({ isBeginning: true, isEnd: false });

  const handlePrevArrowClick = () => {
    if (swiperRef.current && !swiperRef.current.isBeginning) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNextArrowClick = () => {
    if (swiperRef.current && !swiperRef.current.isEnd) {
      swiperRef.current.slideNext();
    }
  };

  const shouldBeFullWidth = isFullWidth && (swiperRef.current?.slides.length ?? 0) > 1;

  const handleSwiperUpdate = (swiper: SwiperType) => {
    setSwiperState({
      isBeginning: swiper.isBeginning,
      isEnd: swiper.isEnd,
    });
  };

  return (
    <CarouselWrapper
      data-test="carousel-wrapper"
      className={className}
      style={{ width: shouldBeFullWidth ? '100%' : undefined }}
    >
      {isStaticArrows && !swiperState.isBeginning && (
        <CarouselSlideLeft onClick={handlePrevArrowClick} />
      )}
      <Swiper
        modules={[Pagination]}
        onSwiper={(swiper: SwiperType) => {
          swiperRef.current = swiper;
          handleSwiperUpdate(swiper);
        }}
        onSlideChange={handleSwiperUpdate}
        spaceBetween={20}
        slidesPerView={swiperRef.current?.slides.length === 1 ? 1 : slidesPerView}
        slidesPerGroup={1}
        threshold={30}
        loop={false}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          bulletClass: 'swiper-pagination-bullet',
          bulletActiveClass: 'swiper-pagination-bullet-active',
          clickable: enableDots,
        }}
        speed={300}
        breakpoints={{
          480: { slidesPerView: slidesPerView, spaceBetween: 24 },
          768: { slidesPerView: slidesPerView, spaceBetween: 20 },
          1024: { slidesPerView: slidesPerView, spaceBetween: 20 },
        }}
        className={className}
      >
        {React.Children.map(children, (child, index) => (
          <SwiperSlide
            key={index}
            className="relative"
            style={{ width: shouldBeFullWidth ? '100%' : undefined }}
          >
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="absolute pb-4 sm:pb-20 md:pb-6 flex gap-2 justify-center w-full swiper-pagination"></div>

      {isStaticArrows && !swiperState.isEnd && <CarouselSlideRight onClick={handleNextArrowClick} />}
    </CarouselWrapper>
  );
};
